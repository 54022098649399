import request from "@/utils/request"

export default {
	apiGetPage: data =>
		request("/trainings/paged-query", {
			method: "get",
			params: data
		}),
	apiGetList: data =>
		request("/trainings/full-query", {
			method: "get",
			params: data
		}),
	// 新增
	apiAdd: data =>
		request("/training", {
			method: "post",
			body: data
		}),
	// 获取详情
	apiGet: data => request(`/training/${data.id}`),
	// 修改
	apiUpdate: data =>
		request(`/training/${data.id}`, {
			method: "put",
			body: data
		}),
	// 删除/禁用
	apiDelete: data =>
		request(`/training/${data.id}`, {
			method: "delete"
		}),
	// 上传培训文件
	uploadFile: (data, params, signal) =>
		request("/training/upload-files", {
			method: "post",
			body: data,
			signal,
			params
		}),
	// 删除文件
	delFile: fileId =>
		request(`/training/file/${fileId}`, {
			method: "delete"
		}),
	//  获取已上传文件：
	getUploadFile: trainingId => request(`/training/${trainingId}/files`),
	//  查询培训人员信息
	getTrainUser: (id, data) =>
		request(`/training/${id}/users`, {
			method: "get",
			params: data
		}),
	// 获取培训信息
	getSignIn: (trainingId, data) =>
		request(`/training/${trainingId}`, {
			method: "get",
			params: data
		}),
	//  新增培训人员 个人签到
	setSignIn: (trainingId, data) =>
		request(`/training/${trainingId}/users`, {
			method: "post",
			body: data
		}),
	// 普通用户 培训签到
	commonSignIn: (trainingId, data) =>
		request(`/training/${trainingId}/out-user-sign`, {
			method: "post",
			body: data
		}),
	// 更改用户培训状态 合格 不合格
	apiResult: (trainingUserId, data) =>
		request(`/training-user/${trainingUserId}/edit-result`, {
			method: "put",
			body: data
		})
}
