<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="培训课题">
				<Input
					clearable
					v-model="searchParams.title"
					placeholder="请输入培训课题"
				></Input>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="培训人员名称">
				<Input
					clearable
					v-model="searchParams.userName"
					placeholder="请输入培训人员名称"
				></Input>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="培训时间" placement="top">
				<DatePicker
					type="daterange"
					placeholder="请选择开始结束日期"
					:transfer="true"
					:clearable="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					v-model="searchParams.time"
				></DatePicker>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="培训级别">
				<Select
					v-model="searchParams.grade"
					placeholder="请选择培训级别"
					transfer
					clearable
				>
					<Option
						:value="grade.id"
						v-for="grade in trainGradeList"
						:key="grade.id"
						>{{ grade.name }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="培训方式">
				<Select
					v-model="searchParams.way"
					placeholder="请选择培训方式"
					transfer
					clearable
				>
					<Option
						:value="grade.id"
						v-for="grade in trainTypeList"
						:key="grade.id"
						>{{ grade.name }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="培训类型">
				<Select
					v-model="searchParams.category"
					transfer
					clearable
					placeholder="请选择培训类型"
				>
					<Option
						:value="grade.id"
						v-for="grade in trainCateList"
						:key="grade.id"
						>{{ grade.name }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="培训科室">
				<Select
					v-model="searchParams.department"
					filterable
					clearable
					transfer
					placeholder="请选择培训科室"
				>
					<Option v-for="item in departList" :key="item.id" :value="item.id"
						>{{ item.name }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				@click="handleAddShow"
				v-if="
					isPermission('btn_training_admin') || isPermission('btn_training_add')
				"
				>新增
			</Button>
			<Button :loading="exportLoading" @click="exportExcel">导出 </Button>
			<Button @click="exportTemplate">培训模板 </Button>
			<Upload
				ref="upload"
				v-if="
					isPermission('btn_training_admin') || isPermission('btn_training_add')
				"
				:headers="{ token: $store.state.user.token }"
				:show-upload-list="false"
				accept=".xlsx,.xls"
				:before-upload="beforeUpload"
				:on-success="onSuccess"
				:on-error="onError"
				:action="`${$baseUrl}/training/import`"
				style="display: inline-block"
			>
				<!--:data="extraData"-->
				<Button>培训导入</Button>
			</Upload>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
			ref="table"
		>
			<template slot-scope="{ row }" slot="lecturer">
				{{ generateLecturerList(row) }}
			</template>
			<template slot-scope="{ row }" slot="student">
				{{ generateStudentList(row).nameList }}
			</template>
			<template slot-scope="{ row }" slot="studentNum">
				{{ generateStudentList(row).num }}
			</template>
		</CtmsDataGrid>
		<Modal
			class="qr-code-modal"
			v-model="QRcodeModal"
			@on-cancel="close"
			:fullscreen="fullscreen"
			:mask-closable="false"
		>
			<div slot="header" class="modal-header">
				<h2>培训二维码</h2>
				<a
					href="javascript:"
					class="fullscreen"
					@click="
						fullscreen = !fullscreen
						getQRcode(currentRow)
					"
				>
					<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
						<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
						<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
					</Tooltip>
				</a>
			</div>
			<div :class="['QR-wrap', { 'QR-full-wrap': fullscreen }]">
				<Spin v-if="uuidLoading" fix></Spin>
				<div id="qrcode"></div>
			</div>
			<div slot="footer">
				<Button type="primary" @click="close">确定 </Button>
			</div>
		</Modal>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:trainCateList="trainCateList"
			:trainGradeList="trainGradeList"
			:trainTypeList="trainTypeList"
			:id="updateId"
		></Add>
		<!--查看培训详情-->
		<view-detail
			:visible="detailVisible"
			:id="detailId"
			:row="currentRow"
			@onCancel="detailVisible = false"
		></view-detail>
	</div>
</template>

<script>
import api from "@/api/train/train"
import publicApi from "@/api/public"
import { mapState } from "vuex"
import XLSX from "xlsx"
import Moment from "moment"
import QRcode from "qrcodejs2"
import { formDataToMD5 } from "@/utils/util"
import Add from "./Add.vue"
import ViewDetail from "./ViewDetail.vue"

const { apiGetPage, apiGetList, apiDelete } = api
const { publicGetDictionaryList, publicGetDepartmentList } = publicApi

export default {
	name: "index",
	components: {
		Add,
		ViewDetail
	},
	data() {
		return {
			addVisible: false,
			detailVisible: false,
			fullscreen: false,
			detailId: "",
			updateId: "",
			departList: [],
			extraData: {
				md5: ""
			},
			listData: [],
			columns: [
				{
					title: "培训课题",
					key: "title",
					minWidth: 160
				},
				{
					title: "培训内容",
					key: "content",
					width: 200
					// nowrap: true,
				},
				{
					title: "培训时间",
					key: "time",
					minWidth: 350,
					render: (h, { row }) =>
						h(
							"span",
							{},
							`${row.startTime.slice(0, 16)} ~ ${row.endTime.slice(0, 16)}`
						)
				},
				{
					title: "培训地点",
					key: "address",
					width: 200
					// nowrap: true,
				},
				{
					title: "培训方式",
					key: "wayName",
					minWidth: 160
				},
				{
					title: "培训状态",
					key: "status",
					minWidth: 100,
					render: (h, { row }) => {
						let text = ""
						let color = "#000"
						if (row.status === 0) {
							text = "未开始"
						} else if (row.status === 1) {
							text = "进行中"
							color = "green"
						} else {
							text = "已结束"
							color = "red"
						}
						return h(
							"span",
							{
								style: {
									color
								}
							},
							text
						)
					}
				},
				{
					title: "培训类型",
					key: "categoryName",
					minWidth: 160
				},
				{
					title: "培训级别",
					key: "gradeName",
					minWidth: 160
				},
				{
					title: "培训人（培训单位）",
					key: "lecturer",
					minWidth: 200,
					slot: "lecturer"
				},
				{
					title: "培训科室",
					key: "departmentName",
					minWidth: 200
				},
				{
					title: "参加培训人员",
					key: "student",
					minWidth: 300,
					slot: "student"
				},
				{
					title: "参加培训人数",
					key: "studentNum",
					minWidth: 150,
					slot: "studentNum"
				},
				{
					title: "操作",
					key: "action",
					width: 300,
					fixed: "right",
					renderButton: ({ row }) => {
						const btnList = [
							{
								label: "修改",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "删除",
								on: {
									click: this.handleSwitchState
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							},
							{
								label: "生成二维码",
								on: {
									click: this.handleGenerateQRcode
								}
							},
							{
								label: "查看培训详情",
								on: {
									click: this.handleViewDetail
								}
							}
						]

						const isHasAdd =
							this.isPermission("btn_training_admin") ||
							(this.isPermission("btn_training_add") &&
								row.createdId === this.info.id)

						if (!isHasAdd) {
							btnList[0] = null
							btnList[1] = null
						}
						if (row.status === 2) {
							btnList[1] = null
							btnList[2] = null
						}
						return btnList.filter(item => !!item)
					}
				}
			],
			loading: false,
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			times: [],
			searchParams: {
				title: "",
				time: [null, null],
				userName: "",
				grade: "",
				category: "",
				way: "",
				department: ""
			},
			oldSearchParams: {
				title: "",
				time: [null, null],
				userName: "",
				grade: "",
				category: "",
				way: "",
				department: ""
			},
			exportLoading: false,
			// 培训类型
			trainCateList: [],
			// 培训级别
			trainGradeList: [],
			// 培训方式
			trainTypeList: [],
			uuid: "",
			uuidLoading: false,
			QRcodeModal: false,
			qrcode: "",
			time: null,
			currentRow: null
		}
	},
	created() {
		this.initList()
		this.getPublicData()
	},
	computed: {
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 130
			},
			trainCategoryList: state => state.enumerate.TRAINING_CATG,
			trainWayList: state => state.enumerate.TRAINING_WAY
		}),
		...mapState("permission", ["systemActionPermissions"]),
		...mapState("user", ["info"])
	},
	methods: {
		getPublicData() {
			// 获取数据字典
			this.$asyncDo(async () => {
				const res = await publicGetDictionaryList()
				if (res) {
					this.trainCateList = res.data[14].leaf.filter(x => x.obj.isDel === 0)
					this.trainGradeList = res.data[15].leaf.filter(x => x.obj.isDel === 0)
					this.trainTypeList = res.data[13].leaf.filter(x => x.obj.isDel === 0)
				}
				const res2 = await publicGetDepartmentList({
					orgId: 1
				})
				if (res2) {
					this.departList = res2.data
				}
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					time: undefined
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		handleGenerateQRcode({ row }) {
			this.currentRow = row
			this.fullscreen = false
			this.QRcodeModal = true
			this.time && window.clearInterval(this.time)
			this.getQRcode(row)
			this.time = setInterval(() => {
				this.getQRcode(row)
			}, 45000)
		},
		// 生成二维码
		getQRcode(row) {
			let url = window.location.href.split("#")[0]
			url += "#/mobile/login"
			this.uuidLoading = true
			this.uuid = `${url}?id=${row.id}&time=${new Date().getTime()}`.toString(
				16
			)
			if (this.qrcode) {
				this.qrcode.makeCode(this.uuid)
			} else {
				this.qrcode = new QRcode("qrcode", {
					width: this.fullscreen ? 300 : 100,
					height: this.fullscreen ? 300 : 100, // 高度
					text: this.uuid // 二维码内容
				})
			}
			this.uuidLoading = false
		},
		close() {
			this.QRcodeModal = false
			this.time && window.clearInterval(this.time)
		},
		// 查看培训详情
		handleViewDetail({ row }) {
			this.currentRow = row
			this.detailId = row.id
			this.detailVisible = true
		},
		// 文件上传前
		beforeUpload(file) {
			formDataToMD5(file, md5Str => {
				this.extraData.md5 = md5Str
				// 获取md5后进行文件上传
				this.$refs.upload.post(file)
			})
			return false
		},
		// 文件上传成功后
		onSuccess(res) {
			if (res.code !== 0) {
				this.$Message.error(res.message)
			} else {
				this.$Message.success("导入成功！")
				this.initList()
			}
		},
		onError(error) {
			this.$Message.error(error)
		},
		// 导出模板
		exportTemplate() {
			window.open(
				`${this.$baseUrl}/training/template-download?token=${this.$store.state.user.token}`
			)
		},
		// 导出操作记录
		exportExcel() {
			this.$asyncDo(async () => {
				this.exportLoading = true
				if (this.oldSearchParams.time[0] && this.oldSearchParams.time[1]) {
					Object.assign(this.oldSearchParams, {
						startTime: Moment(this.oldSearchParams.time[0]).format(
							"YYYY-MM-DD"
						),
						endTime: Moment(this.oldSearchParams.time[1]).format("YYYY-MM-DD")
					})
				}
				const res = await apiGetList({
					...this.oldSearchParams,
					time: undefined
				})
				if (res) {
					const headers = {
						title: "培训课题",
						content: "培训内容",
						time: "培训时间",
						address: "培训地点",
						wayName: "培训方式",
						status: "培训状态",
						categoryName: "培训类型",
						gradeName: "培训级别",
						lecturer: "培训人",
						department: "培训科室",
						student: "参加培训人员",
						studentNum: "参加培训人数"
					}
					const { data } = res
					const dateStr = Moment().format("YYYY-MM-DD HH:mm:ss")
					const fileName = `培训记录_${dateStr}.xlsx`
					const exportData = []
					exportData.push(Object.values(headers))
					data.forEach(d => {
						const item = []
						Object.keys(headers).forEach(k => {
							if (k === "lecturer") {
								item.push(this.generateLecturerList(d))
							} else if (k === "student") {
								item.push(this.generateStudentList(d).nameList)
							} else if (k === "studentNum") {
								item.push(this.generateStudentList(d).num)
							} else if (k === "time") {
								item.push(
									`${d.startTime.slice(0, 16)} ~ ${d.endTime.slice(0, 16)}`
								)
							} else if (k === "status") {
								item.push(
									d.status === 0
										? "未开始"
										: d.status === 1
											? "进行中"
											: "已结束"
								)
							} else if (k === "department") {
								item.push(d.departmentName)
							} else {
								item.push(d[k])
							}
						})
						exportData.push(item)
					})
					const sheet = XLSX.utils.aoa_to_sheet(exportData)
					const book = XLSX.utils.book_new()
					XLSX.utils.book_append_sheet(book, sheet, "培训记录")
					XLSX.writeFile(book, fileName)
				}
				this.exportLoading = false
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				title: "",
				time: [null, null],
				userName: "",
				grade: "",
				category: "",
				way: "",
				department: ""
			}
			this.$nextTick(() => {
				this.searchHandle()
			})
		},
		searchHandle() {
			if (this.searchParams.time[0] && this.searchParams.time[1]) {
				Object.assign(this.searchParams, {
					startTime: Moment(this.searchParams.time[0]).format("YYYY-MM-DD"),
					endTime: Moment(this.searchParams.time[1]).format("YYYY-MM-DD")
				})
			} else {
				Object.assign(this.searchParams, {
					startTime: "",
					endTime: ""
				})
			}
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.updateId = ""
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		// 修改显示
		handleUpdateShow({ row }) {
			this.addVisible = true
			this.updateId = row.id
		},

		generateLecturerList({ trainingUserList }) {
			return trainingUserList
				.filter(item => item.role === "lecturer" && item.userName)
				.map(
					item =>
						`${item.userName}${item.userOrgName ? `（${item.userOrgName}）` : ""}`
				)
				.join("；")
		},

		generateStudentList({ trainingUserList }) {
			const list = trainingUserList
				.filter(item => item.role === "student")
				.map(item => item.userName)
			return {
				nameList: list.join("；"),
				num: list.length
			}
		},
		// 删除
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiDelete({ id: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				}
				this.loading = false
			})
		},
		isPermission(code) {
			return this.systemActionPermissions.indexOf(code) > -1
		}
	}
}
</script>
<style lang="less">
.qr-code-modal {
	.modal-header {
		overflow: hidden;

		h2 {
			float: left;
		}

		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}

		.iconfont {
			font-size: 20px;
		}
	}
}

/* 二维码*/

.QR-wrap {
	position: relative;
	margin: 0 auto;
	box-sizing: border-box;
	width: 220px;
	height: 220px;
	border-style: solid;
	border-width: 2px;
	border-image-source: linear-gradient(0deg, #fe8b38 0%, #fda21e 100%);
	border-image-slice: 1;

	#qrcode {
		display: inline-block;
	}

	img {
		width: 216px;
		height: 216px;
		border: 15px solid #fff;
	}

	&.QR-full-wrap {
		width: 400px;
		height: 400px;
		margin-top: 15vh;

		img {
			width: 396px;
			height: 396px;
		}
	}
}
</style>
