<template>
	<Modal
		class="train-detail-view-modal"
		:value="visible"
		width="1050"
		:fullscreen="fullscreen"
		:mask-closable="false"
		@on-cancel="onCancel"
		:styles="{
			top: fullscreen ? 0 : '20px',
			height: fullscreen ? '100%' : '680px'
		}"
	>
		<div slot="header" class="modal-header">
			<h2>培训详情</h2>
			<a
				href="javascript:"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<!--<div :style="{height: wrapHeight+'px',overflow:'auto', fontSize: '14px'}">-->
		<div :style="{ fontSize: '14px' }">
			<Spin fix v-if="loading"></Spin>
			<div
				v-if="row"
				style="padding-bottom: 10px; border-bottom: 1px solid #e8eaec"
			>
				<Row>
					<Col :span="24"> 培训课题：{{ row.title }} </Col>
					<Col :span="24"> 培训内容：{{ row.content }} </Col>
					<Col :span="24">
						培训时间：{{
							`${row.startTime.slice(0, 16)} ~ ${row.endTime.slice(0, 16)}`
						}}
					</Col>
					<Col :span="12"> 培训地点：{{ row.address }} </Col>
					<Col :span="12"> 培训级别：{{ row.gradeName }} </Col>
				</Row>
				<Row>
					<Col :span="12"> 培训方式：{{ row.wayName }} </Col>
					<Col :span="12"> 培训类型：{{ row.categoryName }} </Col>
					<Col :span="12"> 培训科室：{{ row.departmentName }} </Col>
					<Col :span="24"> 培训人：{{ generateLecturerList(row) }} </Col>
				</Row>
			</div>
			<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
				<CtmsSearchBoxItem :span="1" label="参加培训人员名称">
					<Input
						clearable
						v-model="searchParams.userName"
						placeholder="请输入培训人员名称"
					></Input>
				</CtmsSearchBoxItem>
				<CtmsSearchBoxItem :span="1" label="参加培训人员科室">
					<Input
						clearable
						v-model="searchParams.deptName"
						placeholder="请输入科室名称"
					></Input>
				</CtmsSearchBoxItem>
			</CtmsSearchBox>
			<div style="border-bottom: 1px solid #eee">
				<CtmsDataGrid
					:height="gridHeight"
					:columns="columns"
					:data="listData"
					:page="page"
					ref="table"
				>
					<template slot-scope="{ row }" slot="lecturer">
						{{ generateLecturerList(row) }}
					</template>
					<template slot-scope="{ row }" slot="student">
						{{ generateStudentList(row).nameList }}
					</template>
				</CtmsDataGrid>
			</div>
			<div class="train-files">
				<span class="train-files-title">培训附件：</span>
				<div
					style="width: calc(100% - 100px)"
					v-if="fileList && fileList.length"
				>
					<p v-for="(item, index) in fileList" :key="index" class="file-item">
						<span class="file-item-name">{{ item.name }}</span>
						<span class="file-item-actions">
							<Icon
								type="ios-eye-outline"
								class="preview-icon"
								@click="handlePreview(item)"
								title="预览"
								:size="24"
							/>
							<Icon
								type="ios-cloud-download-outline"
								@click="handleDownload(item)"
								class="download-icon"
								title="下载"
								:size="20"
							/>
						</span>
					</p>
				</div>
				<span v-else>--</span>
			</div>
		</div>
		<div slot="footer">
			<Button @click="onCancel" :loading="loading">关闭 </Button>
		</div>
		<DocumentPreview
			:visible="documentPreviewVisible"
			:documentSrc="documentSrc"
			:id="'trainViewDocumentPreview'"
			@onCancel="documentPreviewVisible = false"
		></DocumentPreview>
	</Modal>
</template>

<script>
import api from "@/api/train/train"
import publicApi from "@/api/public"
import DocumentPreview from "@/components/BusinessComponents/Document/DocumentPreview.vue"
import { mapState } from "vuex"
import docType from "@/config/docType"

const { getTrainUser, apiResult, getUploadFile } = api

const { publicGetDictionaryList } = publicApi

export default {
	name: "ViewTrain",
	components: {
		DocumentPreview
	},
	props: {
		visible: {
			default: false
		},
		id: {
			default: ""
		},
		row: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			loading: false,
			fullscreen: false,
			documentPreviewVisible: false,
			documentSrc: "",
			// 科室
			trainCateList: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				userName: "",
				deptName: ""
			},
			oldSearchParams: {
				userName: "",
				deptName: ""
			},
			fileList: [],
			listData: [],
			columns: [
				{
					title: "参加培训人员",
					key: "userName",
					minWidth: 200
				},
				{
					title: "科室",
					key: "deptName",
					minWidth: 120,
					nowrap: true
				},
				{
					title: "培训签到时间",
					key: "signedTime",
					minWidth: 160,
					render: (h, { row }) => h("span", {}, row.signedTime || "--")
				}
			]
		}
	},
	computed: {
		...mapState({
			trainCategoryList: state => state.enumerate.TRAINING_CATG
		}),
		gridHeight() {
			return this.fullscreen ? document.body.clientHeight - 530 : 380
		},
		// wrapHeight() {
		//   return this.fullscreen ? (document.body.clientHeight - 140) : 770;
		// },
		...mapState("user", ["token"])
	},
	watch: {
		visible() {
			if (this.visible) {
				this.init()
				this.getUploadFile()
			}
		}
	},
	methods: {
		async init() {
			this.loading = true
			const res = await getTrainUser(this.id, {
				...this.oldSearchParams,
				pageNum: this.page.current,
				pageSize: this.page.pageSize
			})
			if (res) {
				this.listData = res.data.list || []
				this.page.total = res.data.total
			}
			this.loading = false
		},
		// 获取上传的附件
		async getUploadFile() {
			const res = await getUploadFile(this.id)
			if (res) {
				this.fileList = res.data || []
			}
		},
		getPublicData() {
			// 获取数据字典
			this.$asyncDo(async () => {
				const res = await publicGetDictionaryList()
				if (res) {
					this.trainCateList = res.data[14].leaf
				}
			})
		},
		generateLecturerList({ trainingUserList }) {
			return trainingUserList
				.filter(item => item.role === "lecturer")
				.map(
					item =>
						`${item.userName}${item.userOrgName ? `（${item.userOrgName}）` : ""}`
				)
				.join("；")
		},
		generateStudentList({ trainingUserList }) {
			const list = trainingUserList
				.filter(item => item.role === "student")
				.map(item => item.userName)
			return {
				nameList: list.join("；"),
				num: list.length
			}
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.init()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.init()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				userName: "",
				deptName: ""
			}
			this.$nextTick(() => {
				this.searchHandle()
			})
		},
		searchHandle() {
			this.page.current = 1
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.init()
			})
		},
		onCancel() {
			this.searchParams = {
				userName: "",
				deptName: ""
			}
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.fullscreen = false
			this.page.current = 1
			this.$emit("onCancel")
		},
		// 预览
		handlePreview(file) {
			const type = file.suffix && file.suffix.toUpperCase()
			if (type && docType.previewTypes.includes(type)) {
				// 预览
				this.documentSrc = `${this.$baseUrl}/document/attachment/inline/${file.id}?isTransfer=1&isIgnorePlaceholder=1&token=${this.$store.state.user.token}`
				this.documentPreviewVisible = true
			} else {
				// 下载
				window.open(
					`${this.$baseUrl}/document/attachment/download/${file.id}?token=${this.$store.state.user.token}`
				)
			}
		},
		// 下载
		handleDownload(file) {
			window.open(
				`${this.$baseUrl}/document/attachment/download/${file.id}?token=${this.$store.state.user.token}`
			)
		}
	}
}
</script>

<style lang="less">
.train-detail-view-modal {
	.ivu-modal-body {
		padding-bottom: 0;
	}

	.ivu-table:before {
		height: 0;
	}

	.modal-header {
		overflow: hidden;

		h2 {
			float: left;
		}

		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}

		.iconfont {
			font-size: 20px;
		}
	}

	.consultAdd-form .title {
		font-size: 15px;
		font-weight: bold;
		padding: 16px 0;
		border-top: dashed 1px #515a6e;
	}

	.consultAdd-form .margin0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.model-sevice {
		list-style: none;
		padding-left: 10px;

		li {
			position: relative;
			line-height: 36px;
			border-bottom: 1px dotted #ccc;

			&:last-of-type {
				border: none;
			}

			h4 {
				color: #515a6e;
			}
		}
	}

	.train-files {
		margin: 10px 0;
		display: flex;
		line-height: 32px;

		.train-files-title {
			width: 100px;
			text-align: center;
		}

		.file-item {
			padding: 0 10px;
			border-radius: 6px;
			overflow: hidden;

			&:hover {
				background-color: #eee;

				.file-item-name {
					color: #2d8cf0;
				}

				.file-item-actions {
					display: block;
				}
			}

			.file-item-actions {
				float: right;
				display: none;
				margin: 0 20px;

				.preview-icon {
					cursor: pointer;

					&:hover {
						color: #2d8cf0;
					}
				}

				.download-icon {
					margin-left: 12px;
					cursor: pointer;

					&:hover {
						color: #2d8cf0;
					}
				}
			}
		}
	}
}
</style>
