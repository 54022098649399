<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		class="add-train-wrap"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rulesValid">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="培训课题" prop="title">
						<Input v-model="formData.title" placeholder="请输入培训课题" />
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="培训内容" prop="content">
						<Input
							type="textarea"
							class="train-area"
							:row="5"
							placeholder="请输入培训内容"
							v-model="formData.content"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem
						label="培训时间"
						key="trainDates"
						:rules="trainDateRules.trainDates"
						prop="trainDates"
					>
						<DatePicker
							type="datetimerange"
							placeholder="请选择培训开始和结束时间"
							:clearable="false"
							style="width: 100%"
							:value="formData.trainDates"
							format="yyyy-MM-dd HH:mm"
							@on-change="handleTrainDatesChange"
						></DatePicker>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="培训地点" prop="address">
						<Input v-model="formData.address" placeholder="请输入培训地点" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="培训级别" prop="grade">
						<Select
							v-model="formData.grade"
							filterable
							clearable
							placeholder="请选择培训级别"
						>
							<Option
								:value="grade.id"
								v-for="grade in trainGradeList"
								:key="grade.id"
								>{{ grade.name }}
							</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="培训方式" prop="way">
						<Select
							v-model="formData.way"
							filterable
							clearable
							placeholder="请选择培训方式"
						>
							<Option
								:value="type.id"
								v-for="type in trainTypeList"
								:key="type.id"
								>{{ type.name }}
							</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="培训类型" prop="category">
						<Select
							v-model="formData.category"
							filterable
							clearable
							placeholder="请选择培训类型"
						>
							<Option
								v-for="cate in trainCateList"
								:key="cate.id"
								:value="cate.id"
								>{{ cate.name }}
							</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="培训科室" prop="department">
						<Select
							v-model="formData.department"
							filterable
							clearable
							placeholder="请选择培训科室"
						>
							<Option v-for="item in departList" :key="item.id" :value="item.id"
								>{{ item.name }}
							</Option>
						</Select>
					</FormItem>
				</Col>
			</Row>
			<div class="train-group-wrapper">
				<h3 style="font-weight: bold">培训人</h3>
				<Row
					v-for="(item, index) in teacherList"
					:gutter="8"
					style="margin-bottom: 5px"
					:key="'t_' + index"
				>
					<Col span="11">
						<Input
							v-model="item.userName"
							placeholder="请输入培训人名称"
						></Input>
					</Col>
					<Col span="11">
						<Input
							v-model="item.userOrgName"
							placeholder="请输入培训人单位"
						></Input>
					</Col>
					<Col span="2">
						<Button @click="handleDelTeacher(index)">删除</Button>
					</Col>
				</Row>
				<Button @click="handleAddTeacher" style="margin-top: 10px"
					>+ 添加培训人</Button
				>
			</div>
			<div class="train-group-wrapper">
				<Row>
					<Col span="24">
						<FormItem label="参加培训人员" style="margin-bottom: 0">
							<span class="total">（合计：{{ userNum }}人）</span>
							<Select
								v-model="studyList"
								:label-in-value="true"
								multiple
								filterable
								@on-change="handleChange"
								placeholder="培训人员"
							>
								<Option
									:value="staff.id + '_' + staff.realName"
									v-for="staff in staffList"
									:key="staff.id"
									:label="staff.realName"
								></Option>
							</Select>
							<p style="font-size: 12px; line-height: 24px; margin-top: 5px">
								提示：系统用户现场扫码签到后，会自动显示在此处。也可以直接选择培训人员，选择后默认该人员是培训已签到状态。通常适用于补录以往培训人员，新增未开始培训时无需选择。
							</p>
						</FormItem>
					</Col>
				</Row>
			</div>
			<Row style="padding-bottom: 50px" v-if="visible">
				<Col span="24">
					<Upload
						action="/"
						ref="upload"
						:before-upload="file => handleBeforeUpload(file)"
						:on-success="onSuccess"
						:on-error="onError"
						:on-remove="onRemove"
						:on-preview="onPreview"
						:show-upload-list="false"
					>
						<!--:default-file-list="fileList"-->
						<Button :loading="uploadLoading">{{
							uploadLoading ? "上传中..." : "上传附件"
						}}</Button>
					</Upload>
					<div v-if="fileList && fileList.length" style="padding-top: 10px">
						<p v-for="(item, index) in fileList" :key="index" class="file-item">
							<span class="file-item-name">{{ item.name }}</span>
							<span class="file-item-actions">
								<Icon
									type="ios-eye-outline"
									class="preview-icon"
									@click="onPreview(item)"
									title="预览"
									:size="24"
								/>
								<Icon
									type="ios-cloud-download-outline"
									@click="handleDownload(item)"
									class="download-icon"
									title="下载"
									:size="20"
								/>
								<Icon
									type="ios-close"
									@click="onRemove(item)"
									class="download-icon"
									title="删除"
									:size="24"
								/>
							</span>
						</p>
					</div>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="uploadLoading"
				>确定
			</Button>
		</div>
		<DocumentPreview
			:visible="documentPreviewVisible"
			:documentSrc="documentSrc"
			:id="'trainDocumentPreview'"
			@onCancel="documentPreviewVisible = false"
		></DocumentPreview>
	</Modal>
</template>

<script>
import api from "@/api/train/train"
import publicApi from "@/api/public"
import docType from "@/config/docType"
import DocumentPreview from "@/components/BusinessComponents/Document/DocumentPreview.vue"
import moment from "moment"
import { formDataToMD5 } from "@/utils/util"

const { publicGetuserList, publicGetDepartmentList } = publicApi

const { apiGet, apiAdd, apiUpdate, uploadFile, getUploadFile } = api

export default {
	name: "Add",
	props: ["visible", "id", "trainCateList", "trainTypeList", "trainGradeList"],
	components: {
		DocumentPreview
	},
	data() {
		return {
			loading: false,
			uploadLoading: false,
			documentPreviewVisible: false,
			documentSrc: "",
			formData: {
				trainDates: []
			},
			teacherList: [],
			staffList: [],
			studyList: [],
			fileList: [],
			fileIdsList: [],
			title: "",
			trainDateRules: {
				trainDates: {
					required: true,
					message: "培训时间不能为空",
					type: "array",
					fields: {
						0: { required: true, message: "请选择开始时间" },
						1: { required: true, message: "请选择结束时间" }
					}
				}
			},
			rulesValid: {
				title: {
					required: true,
					message: "培训课题不能为空",
					trigger: "blur"
				},
				content: {
					required: true,
					message: "培训内容不能为空",
					trigger: "blur"
				},
				address: {
					required: true,
					message: "培训地点不能为空",
					trigger: "blur"
				},
				way: {
					required: true,
					message: "培训方式不能为空",
					trigger: "blur change"
				},
				category: {
					required: true,
					message: "培训类型不能为空",
					trigger: "blur change"
				},
				department: {
					required: true,
					message: "培训科室不能为空",
					trigger: "blur change"
				},
				grade: {
					required: true,
					message: "培训级别不能为空",
					trigger: "blur change"
				},
				studyList: {
					required: true,
					type: "array",
					min: 1,
					message: "参加培训人员不能为空",
					trigger: "change"
				}
			},
			userNum: 0,
			departList: [],
			controller: null
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.uploadLoading = false
				this.getPublicData()
				this.$refs.form.resetFields()
				this.formData = {
					trainDates: []
				}
				this.teacherList = []
				this.studyList = []
				this.fileList = []
				this.fileIdsList = []
				this.title = "新增培训"
				if (this.id) {
					this.title = "修改培训"
					this.getUploadFile(this.id) // 获取上传文件；
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({
							id: this.id
						})

						if (res) {
							this.teacherList = res.data.trainingUserList.filter(
								item => item.role === "lecturer" && item.userName
							)
							this.studyList = res.data.trainingUserList
								.filter(item => item.role === "student")
								.map(item => `${item.userId}_${item.userName}`)
							// this.userNum = this.studyList.length;
							this.userNum = res.data.inCount
							this.fileIdsList = res.data.fileIds
								? res.data.fileIds.split(",")
								: []
							const {
								title,
								content,
								startTime,
								endTime,
								address,
								grade,
								way,
								category,
								department
							} = res.data
							this.formData = {
								title,
								content,
								address,
								department,
								grade: String(grade),
								way: String(way),
								category: String(category),
								trainDates: [startTime, endTime]
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		async getUploadFile(id) {
			const res = await getUploadFile(id)
			if (res) {
				this.fileList = res.data || []
			}
		},
		handleBeforeUpload(file) {
			this.uploadLoading = true
			this.controller = new AbortController()
			const { signal } = this.controller
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, { md5: md5Str }, signal)
			})
			return false
		},
		// 上传文件
		uploadFile(file, params, signal) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				const res = await uploadFile(fd, params, signal)
				if (res) {
					this.$Message.success("上传成功!")
					const newFile = Object.assign(file, { id: res.data })
					this.fileList.push(newFile)
					this.fileIdsList.push(res.data)
				}
				this.uploadLoading = false
			})
		},
		// 文件上传成功后
		onSuccess(res) {
			if (res.code !== 0) {
				this.$Message.error(res.message)
			} else {
				// this.$Message.success('上传成功');
			}
		},
		onError(error) {
			this.$Message.error(error)
		},
		onRemove(file) {
			let delId = ""
			this.fileList = this.fileList.filter(item => {
				if (file.id) {
					delId = file.id
					return item.id !== file.id
				}
				delId = item.uid === file.uid ? item.id : ""
				return item.uid !== file.uid
			})
			this.fileIdsList = this.fileIdsList.filter(item => item !== delId)
		},
		onPreview(file) {
			let isPreview = false
			if (file.suffix) {
				const fileType = file.suffix && file.suffix.toUpperCase()
				isPreview = docType.previewTypes.includes(fileType)
			} else {
				const type = file.type ? file.type.toUpperCase() : ""
				for (let i = 0; i < docType.previewTypes.length; i++) {
					if (type && type.indexOf(docType.previewTypes[i]) > -1) {
						isPreview = true
						break
					}
				}
			}
			if (isPreview) {
				// 预览
				this.documentSrc = `${this.$baseUrl}/document/attachment/inline/${file.id}?isTransfer=1&isIgnorePlaceholder=1&token=${this.$store.state.user.token}`
				this.documentPreviewVisible = true
			} else {
				// 下载
				window.open(
					`${this.$baseUrl}/document/attachment/download/${file.id}?token=${this.$store.state.user.token}`
				)
			}
		},
		// 下载
		handleDownload(file) {
			window.open(
				`${this.$baseUrl}/document/attachment/download/${file.id}?token=${this.$store.state.user.token}`
			)
		},
		handleTrainDatesChange(value) {
			this.$set(this.formData, "trainDates", value)
		},
		handleChange(value) {
			this.userNum = value.length
			this.$set(this.formData, "studyList", value)
		},
		getPublicData() {
			// 获取数据字典
			this.$asyncDo(async () => {
				const res = await publicGetuserList()
				if (res) {
					this.staffList = res.data
				}

				const res2 = await publicGetDepartmentList({
					orgId: 1
				})
				if (res2) {
					this.departList = res2.data
				}
			})
		},
		onCancel() {
			// 取消上传
			this.controller && this.controller.abort()
			this.$emit("onCancel")
		},
		async onOk() {
			const valid = await this.$refs.form.validate()
			if (valid) {
				const {
					title,
					content,
					trainDates,
					address,
					grade,
					way,
					category,
					department
				} = this.formData
				if (
					!trainDates.length ||
					(trainDates.length && (!trainDates[0] || !trainDates[1]))
				) {
					this.$Message.error("培训开始结束日期不能为空！")
					return false
				}
				const data = {
					address,
					category,
					content,
					grade,
					title,
					way,
					department
				}

				const startTime = moment(trainDates[0]).format("YYYY-MM-DD HH:mm")
				const endTime = moment(trainDates[1]).format("YYYY-MM-DD HH:mm")

				data.startTime = startTime
				data.endTime = endTime

				const teacherTrainingList = this.teacherList
					.filter(val => val.userName)
					.map(item => ({
						...item,
						role: "lecturer",
						trainingId: this.id || null
					}))
				let studyTrainingList = []
				if (this.studyList.length) {
					studyTrainingList = this.studyList.map(item => ({
						userId: item.split("_")[0],
						userName: item.split("_")[1],
						role: "student",
						trainingId: this.id || null
					}))
				}
				data.trainingUserList = [...teacherTrainingList, ...studyTrainingList]
				data.fileIds = this.fileIdsList.join(",")
				this.loading = true
				let res = false
				if (this.id) {
					res = await apiUpdate({
						...data,
						id: this.id
					})
				} else {
					res = await apiAdd(data)
				}
				if (res) {
					this.$emit("onOk")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			}
			return false
		},
		handleAddTeacher() {
			this.teacherList.push({})
		},
		handleDelTeacher(index) {
			this.teacherList.splice(index, 1)
		}
	}
}
</script>

<style lang="less">
.add-train-wrap {
	.train-area {
		textarea.ivu-input {
			font-size: 14px;
		}
	}

	.file-item {
		padding: 2px 10px;
		line-height: 24px;
		border-radius: 6px;
		overflow: hidden;

		&:hover {
			background-color: #eee;

			.file-item-name {
				color: #2d8cf0;
			}

			.file-item-actions {
				display: block;
			}
		}

		.file-item-actions {
			float: right;
			display: none;
			margin-left: 20px;

			.preview-icon {
				cursor: pointer;

				&:hover {
					color: #2d8cf0;
				}
			}

			.download-icon {
				margin-left: 12px;
				cursor: pointer;

				&:hover {
					color: #2d8cf0;
				}
			}
		}
	}

	.train-group-wrapper {
		margin-bottom: 20px;

		.required {
			margin-bottom: 5px;
			font-weight: bold;

			span {
				color: #ed4014;
			}

			&:before {
				content: "* ";
				color: #ed4014;
				font-size: 16px;
			}
		}

		.ivu-form-item {
			position: relative;

			.ivu-form-item-content {
				position: unset;
			}

			.total {
				color: red;
				position: absolute;
				top: 0;
				right: 0;
				font-size: 12px;
				line-height: 16px;
			}
		}
	}
}
</style>
